import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import NextNProgress from "nextjs-progressbar";
import "@/styles/_global.scss";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import Head from "next/head";
import Script from "next/script";
import axios from "axios";
import { baseURL } from "@/services/api";
import RootLayout from "@/components/Ui/Tools/RootLayout/RootLayout";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();

  if (locale) {
    setCookie(null, "locale_path", locale, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  } else {
    setCookie(null, "locale_path", "", {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  }

  return (
    <>
      <Head>
        <meta name="google-site-verification" content="-d3UafEXvFKssScqrxgFIfaegnRjLJe5E7WZgCrU7Qk" />
        {/* <!-- Google tag (gtag.js) -->  */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WNFLVH81LT"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-WNFLVH81LT');  
            `
          }}
        />
        {/*  <!-- Yandex.Metrika counter --> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
         
            ym(97343695, "init", {
                 clickmap:true,
                 trackLinks:true,
                 accurateTrackBounce:true
            });`
          }} />
        {/* <!-- /Yandex.Metrika counter --> */}

        {/* <!-- Google Tag Manager --> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WS9BXCGC');
                  `
          }}
        />
        {/* <!-- End Google Tag Manager --> */}

        <noscript
          dangerouslySetInnerHTML={{
            __html: `<div><img src="https://mc.yandex.ru/watch/97343695" style="position:absolute; left:-9999px;" alt="" /></div>`,
          }}
        />

      </Head>

      <Script
        dangerouslySetInnerHTML={{
          __html: `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
          (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/5ab23e0fd7591465c708c471/default';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
          })();`,
        }}
        strategy="afterInteractive"
      />

      <QueryClientProvider client={queryClient}>
        <NextNProgress color="#222" options={{ showSpinner: false }} />
        <RootLayout data={pageProps.headerData} locale={pageProps.locale}>
          <Component {...pageProps} />
        </RootLayout>
      </QueryClientProvider>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WS9BXCGC"
                height="0" width="0" style="display:none;visibility:hidden"/>`,
        }}
      />
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
}

App.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  const { locale } = ctx;

  const defaultLocale = locale || "az";

  const res = await axios.get(baseURL + "/category/menu", {
    headers: {
      "x-localization": defaultLocale,
    },
  });
  const headerData = res.data;

  return {
    pageProps: {
      headerData,
      locale: defaultLocale,
    },
  };
};
